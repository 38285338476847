



import { Component, Vue } from 'vue-property-decorator'
import Form from '@/shared/components/form/Form.vue'
import { AdminRoutes } from '@/shared/router/admin'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { RoleLevel } from '@/shared/modules/role/role.model'
import { CompanyRoutes } from '@/shared/router/company'
import { AuthRoutes } from '@/shared/router/auth'

@Component({
  components: { Form }
})
export default class Home extends Vue {

  created() {
    if (this.user) {
      if (this.user.role.level === RoleLevel.superadmin) {
        this.$router.push({ name: AdminRoutes.index })
        return
      }

      if (this.user.company) {
        this.$router.push({ name: CompanyRoutes.index, params: { company: this.user.company.slug } })
      }
      return
    }

    this.login()
  }

  login(): void {
    this.$router.push({ name: AuthRoutes.login })
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
